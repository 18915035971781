<template>
  <div class="side-menu next">
    <div class="row info-wrapper mob-hide">
      <div class="col-12">
        <p class="eoi">EOI Booking</p>
        <h2 class="page-heading">Meravilla Pool City</h2>
        <p class="align-data">Shahapur, Maharashtra</p>
      </div>
    </div>
  <div class="typology">
    <h3 class="headline border-bottom-0 pb-0">Select Variant</h3>
    <ul class="list-group custom-inline-box">
      <li class="list-group-item list-group-item-action" v-for="floor in variants">
        <div class="custom-control custom-radio ">
          <input type="radio" :id="floor.key" name="floorSelector" class="custom-control-input" @click="selectFloor(floor)" :checked="selectedFloor !== null && floor.key === selectedFloor.key">
          <label class="custom-control-label" :for="floor.key">
            <div class="floor-section">
              <div class="floor-data">
                <span class="floor-value">{{floor.value}}</span>
                <div class="plot-size"><span>Plot Size</span> {{floor.area}} sqft</div>
                <div class="villa-size"><span>Villa Construction Size</span> {{floor.carpetArea}} sqft</div>
              </div>
              <div class="price-range">
                <span class="price-range-title">Starting from</span>
                <span class="price-value text-right">{{floor.price}}</span>
              </div>
            </div> 
            <span class="divider"></span>
          </label>
        </div>
      </li>
    </ul>
    <div class="side-action">
      <div class="submit-button-section">
        <DownArrow id="down-arrow" v-if="isMobile" v-show="isMobile && isDownVisible"/>
        <span class="d-block submit-button-card" tabindex="0" data-toggle="tooltip">
          <span v-if="isMobile" class="submit-message">Select Variant and Continue</span> 
          <button type="button" class="btn btn-primary btn-block" 
          @click="goToPlans">Continue</button>
          <span class="form-error-text" v-if="isInvalid">Please select the required floor range</span>
          <!-- <router-link to="/Unitplan" class="btn btn-primary btn-block" >Proceed</router-link> -->
      </span>
      </div>
    </div>
  </div>
  <div class="disclaimer"><span>Disclaimer:</span> This submission expresses your interest in our property but does not constitute a confirmed booking. The final plot allocation will occur only after completing the formal property booking process.</div>
  <div class="row back-section">
    <button @click="$router.push({name:'masterplan',query:{...$route.query}})" type="button" class="prevButton">
      <img src="@/assets/images/back-arrow.svg" alt="Back" height="16" width="16">
      Back</button>
    </div>
    <div class="col-12 powered-by">Powered by <span class="powered-by-ppistol"> PropertyPistol&nbsp;Technologies</span></div>

</div>
</template>
<script>
import { masterJson } from '@/assets/scripts/utility.js'
export default {
  components:{
    DownArrow: ()=> import('@/components/Common/DownArrow.vue')
  },
  data(){
    return {
      isInvalid:false,
      isDownVisible:true
    }
  },
  methods: {
    selectFloor(floor) {
      this.isInvalid=false;
      this.$store.dispatch('setFloor', floor);
    },
    goToPlans() {
      if(!this.selectedFloor || !this.selectedFloor.key){
        this.isInvalid = true;
        return;
      }
      this.$store.dispatch('setLoading',true);
      this.$router.push({ name: 'Unit', query:{...this.$route.query} })
    },
    handleScroll(event){
      if(window.innerHeight + window.scrollY < document.body.offsetHeight){
        this.isDownVisible = true;
      }else{
        this.isDownVisible = false;
      }
    }
  },
  computed: {
    variants() {
      return masterJson.Variants;
    },
    selectedFloor() {
      return this.$store.getters.selectedFloor;
    },
    selectedTypology() {
      return this.$store.getters.selectedTypology;
    },
    isMobile(){
      return window && window.innerWidth<=991;
    }
  },
  mounted(){
    if(this.isMobile){
      window.addEventListener('scroll',this.handleScroll,{passive:true});
    }
    let buildings = Object.assign({}, ...this.variants.filter(f => f.key === 'villa2'))
    this.$store.dispatch("setFloor", buildings);
    this.$store.dispatch('setLoading',false);
  },
  unmounted(){
    if(this.isMobile){
      window.removeEventListener('scroll',this.handleScroll,{passive:true});
    }
  }
}
</script>
<style scoped>
h3 {
  color: #000;
  font-size: 16px;
  font-weight: 600;
  padding: 16px;
}

.info-wrapper p.eoi{
    font-weight: 600;
    margin-bottom: 12px;
  }
.side-menu{
   border: none;
}

.disclaimer{
  color: rgba(0, 0, 0, 0.50);
  font-size: 11px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 20px;
}
.disclaimer span{
  color: #000;
  font-weight: 500;
}
 .info-wrapper{
  margin-bottom: 20px;
  padding-right: 15px;
  border-radius: 10px;
  display: flex;
 }

  .info-wrapper h2{
    font-size: 12px;
    font-weight: bold;
    color: #000;
    margin-bottom: 10px;
  }
     .info-wrapper .page-heading {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    font-weight: 700;
    font-size: 24px;
    margin-bottom: 4px;
  }

  .info-wrapper p {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    text-transform: uppercase;
    opacity: 0.5;
    padding-left: 0px;
    padding-bottom: 0px;
    margin-bottom: 14px;
  }
.info-wrapper .align-data {
    font-size: 13px;
    color: #000000;
    line-height: 16px;
    opacity: 0.5;
    text-transform: none;
    padding-left: 0px;
    padding-top: 0;
    margin-bottom: 0;
 }

.side-menu .typology {
  border-radius: 10px;
  border: 1px solid rgba(2, 103, 193, 0.20);
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.03);
  padding-bottom: 16px;
  position: relative;
}

.side-menu .typology .list-group {
  flex-direction: column;
}

.side-menu .typology .list-group-item {
  padding: 0;
  border: none;
}

.list-group{
  display: flex;
}

.list-group-item-action{
  width: 100%;
}
.back-section{
  padding-left: 15px;
}

.custom-control {
  margin-right: 0;
}

.custom-control-label {
  padding: 10px 15px 10px 40px;
  width: 100%;
}

.custom-control-label .divider {
  width: 5px;
  height: 100%;
  border-radius: 0px 4px 4px 0px;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background: 50% 50% no-repeat;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%58A4B0'/%3e%3c/svg%3e");
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before{
  background-color:#ffffff; 
  outline: none;
}

.custom-control-input:not(:disabled):not(:checked):active ~ .custom-control-label::before,
.custom-control-input:not(:disabled):not(:checked):focus ~ .custom-control-label::before{
  border-color:#000000;
}
.custom-control-input:checked ~ .custom-control-label::before {
  border-color:#58A4B0;
  background-color:#ffffff;
}


.custom-radio .custom-control-input:checked ~ .custom-control-label .price-range-title{
  color: rgba(0, 0, 0, 0.5);
}

.custom-control-input:checked ~ .custom-control-label .divider{
  display: none;
}

.typology .custom-control-label::before,
.typology .custom-control-label::after {
  top: 13px;
  box-shadow:none;
  left: 15px;
}

.floor-section{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.floor-value{
  display: block;
  color: #000;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 4px;
}


.plot-size span, .villa-size span, .price-range-title{
  color: rgba(0, 0, 0, 0.50);
  font-size: 12px;
  font-weight: 400;
  display: inline-block;
  margin-right: 2px;
}

.plot-size, .villa-size{
  color: rgba(0, 0, 0, 1);
  font-size: 12px;
  font-weight: 500;
}

.price-range-title{
  margin-bottom: 6px;
  display: block;

}

.price-value{
  color: #000;
  text-align: right;
  font-size: 15px;
  font-weight: 600;
  display: block;
}

.custom-radio .custom-control-input:checked~.custom-control-label::after {
  top: 13px;
  left: 15px;
  margin: 2px;
}
.side-action {
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  gap:15px;
  width: 100%;
  margin-top: 22px;
  background: #fff;
  border-radius: 10px;
}
.prevButton {
  color: rgba(0, 0, 0, 0.5);
  border: none;
  background: transparent;
  font-weight: 600;
  font-size: 16px;
  margin-top: 30px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  transition: 0.3s;
  padding: 0;
}
.prevButton:hover{
  gap: 10px;
}
.side-action  .btn-primary {
  background: #198588;
  border-radius: 4px;
  padding: 16px;
  font-weight: 600;
  font-size: 16px;
  color: #ffffff;
  border-color:#198588;
  box-shadow:none;
}

.side-action  .btn-primary:hover{
  background: #58A4B0;
  border-color:#58A4B0;
}
.side-action  .btn-primary:focus,
.side-action  .btn-primary:active{
  background: #198588;
  border-color:#198588;
}
.side-action .btn-primary:disabled{
  opacity: 1;
}
.side-action  p {
  font-weight: 400;
  font-size: 9px;
  color: #082D57;
  opacity: 0.5;
  text-align: center;
}
.powered-by{
  font-weight: 400;
  font-size: 11px;
  line-height: 13px;
  color: #00000080;
  text-align: center;
  padding-top: 20px;
  margin-top: auto;
}
.powered-by .powered-by-ppistol{
  color: #000000;
}

.submit-button-section{
  text-align: center;
}

.form-error-text{
  color: red;
}

.submit-message{
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  display: inline-block;
  margin-bottom: 16px;
}

@media (max-width:991px) {
  .disclaimer{
    margin-left: 16px;
    margin-right: 16px;
  }
  .mob-hide{
    display: none;
  }
  .powered-by{
    display: none;
  }
  .prevButton{
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 30px;
  }
 
  .side-menu .typology{
    border: none;
  }
  .back-section{
    margin:0;
    padding-left: 0;
  }

  .headline{
    border: none;
    padding-left: 20px;
    padding-right: 20px;
  }

  .side-menu .typology .list-group-item{
    padding-left: 4px;
    padding-right: 4px;
  }

  .submit-button-section{
    position: fixed;
    bottom: 0;
    left: 0;
    z-index:1003;
    width: 100%;
    
  }
  .submit-button-card{
    padding: 16px;
    width: 100%;
    background: #ffffff;
    border: 1px solid rgba(73, 72, 153, 0.2);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.03);
  }

}
</style>